import { EnableAdUnits, LoadAds, ClearAds,EnableRewardedVideo } from '../Lib/gpt-ad-manager';
import { useEffect, useState } from 'react';
import useSessionStore from "../Lib/Context";
import "../App.css";
import Spinner from "../Components/Spinner"
import Text from '../Components/Text';
import {
    BtnContainer,
    Box,
    AppHeader,
    CustomTextContainer

  } from '../Components/ResponsiveLayout';
import Btn from '../Components/Button';
import ErrorPage from '../Components/ErrorPage';
import VideoErrorPage from '../Components/VideoErrorPage';
import { Authenticate } from '../Lib/Authentication';
import { logs } from '../Lib/fetch';
import ReactGA from "react-ga4";


// const googletag = window.googletag || (window.googletag = { cmd: [] });

function WatchVideo2 (){
    const { SessionData, setSessionData } = useSessionStore();
    const [IsLoading, setIsLoading] = useState(true)
    const [RewardedAd, setRewarded] = useState(false)
    const [isRewardedReady, isRewardGranted, videoErrorState] = EnableRewardedVideo({"adUnit":"video_sec"})
    const [IsOnline,setIsOnline] = useState(false)
    const [PageHeader,setPageHeader] = useState("Page2")
    const [DisplayFooter, setDisplayFooter] = useState(false)
    const [DisplayOnlineFooter, setDisplayOnlineFooter] = useState(false)
    const [isAuthenticating, setisAuthenticating] = useState(false)
    const [AuthenticationStatus, setAuthenticationStatus] = useState(false)
    const [ErrorStatus, setErrorStatus] = useState(false)
    const [ClickEventSend2, setClickEventSend2] = useState(true)
    const [Scrolltop, SetScrolltop] = useState(false)
    

    const Footer = () => (
        <footer className="footer">
            <Box className='example-style box-height'>
                <BtnContainer>
                    <Btn className = "sticky-button" onClick={handleClick}>CONTINUE</Btn>
                </BtnContainer>
            </Box>
        </footer>
    );

    const OnlineFooter = () => (
        <footer className="footer">
            <Box className='example-style box-height'>
                <BtnContainer>
                    <Btn onClick={() => setisAuthenticating(true)}>Go Online</Btn>
                </BtnContainer>
            </Box>
        </footer>
    );

    const BodyParagraph = () => (
        <div>
              <CustomTextContainer style={{ flex: 'none' }}>
                <Text.Text>
                    <p>Welcome to
                    <br />
                    {' '} 
                    <Text.Text className="bold-text">
                    ThinkZone
                    </Text.Text>
                    <br />
                    {' '}
                    fast, free Wi-Fi.
                    </p>
                </Text.Text>
             </CustomTextContainer>
        </div>
    )

    const OnlineBodyParagraph = () => (
        <div>
            <CustomTextContainer style={{ flex: 'none' }}>
                <Text.Paragraph1 className="bold-text">
                    Almost Online
                </Text.Paragraph1>
                    <br />
                    {' '} 
                <Text.Paragraph1>
                    Click to enjoy your
                </Text.Paragraph1>
                    <br />
                    {' '}
                <Text.Paragraph1>
                    Free Wi-Fi
                </Text.Paragraph1>

             </CustomTextContainer>
        </div>
    )
    
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.send({ hitType: "pageview", page: "/watchvideo2", title: "Watch Video 2"});
            setTimeout(() => {
                setIsLoading(false);
            },2000);
    },[])

    useEffect(() => {
        if(!IsLoading){
            setTimeout(() => {
                setDisplayFooter(true);
              }, 2000); 
        }
       
    }, [IsLoading])

    useEffect(() =>{
        if(isRewardGranted){
            setPageHeader("Online 2 Page")
            setIsOnline(true)
            setDisplayFooter(false)
            setTimeout(() => {
                setDisplayOnlineFooter(true)
              }, 2000); 
        }
    },[isRewardGranted]);

    useEffect(() =>{
        if(isAuthenticating){
            setIsLoading(true)
            setSessionData.auth_domain = true
            Authenticate(SessionData,setSessionData);
            setisAuthenticating(false)
        }
    
    }, [isAuthenticating])
    
    useEffect(() => {
        if (SessionData.auth_result){
            console.group("======Sending Click Event======")
            console.log("sending data")
            if(SessionData.auth_result.status !== "error"){
                if(ClickEventSend2){
                    console.log("Click Event is True")
                    console.log("this is journey2") 
                    console.log("clickEvent is true")
                    let clickEvent2 = {
                        "sessionID": SessionData.sessionID,
                        "clientID": SessionData.clientID,
                        "button_id": "go_online_2",
                        "button_url": SessionData.UserRedirectUrl_2,
                        "event": "clickEvent",
                        "campaign_code": SessionData.campaign_code_fb
                    }
                    logs(clickEvent2,SessionData)
                    setClickEventSend2(false)
                    setAuthenticationStatus(true)}
                    console.groupEnd("=======Ending console Group for Click Event=========")
                
            }else if(SessionData.auth_result.status === "error"){
                setErrorStatus(true)
            }
        }
        
    },[SessionData,ClickEventSend2]);

    useEffect(() => {
        if(AuthenticationStatus){
            window.open(SessionData.UserRedirectUrl_2,'_blank')
            window.location.href = SessionData.UserRedirectUrl_2
        }
    },[AuthenticationStatus,SessionData])

    useEffect(() => {
        if(Scrolltop){
            window.scrollTo(0, 0);
        }
      }, [Scrolltop]);


    function handleClick(){
        setRewarded(true)
        SetScrolltop(true)
        isRewardedReady.makeRewardedVisible()
    }

    if(videoErrorState && !isRewardGranted){
        return(<VideoErrorPage />)
    }

    if(RewardedAd){
        ClearAds(["page2_header","page2_display"])  
    }
    if(ErrorStatus){
        return(<ErrorPage />)
    }
   
    
    
    return (
        <div>
            <div>
                {IsLoading && <Spinner />}
            </div>
            <div>
                {isAuthenticating && <Spinner />}
            </div>
            <div>
                <div>
                    <h1 className='d-none'>{PageHeader}</h1>
                </div>
                <div id="Header">
                <AppHeader>
                    <EnableAdUnits Name="page2_header"  Network="/21967555500/thinkzone/" Targeting={SessionData.AdUnitTargeting.page2_header}/>
                    <EnableAdUnits Name="online2_header"  Network="/21967555500/thinkzone/" Targeting={SessionData.AdUnitTargeting.online2_header}/>
                    <LoadAds Ads={["page2_header"]}/>
                    {IsOnline && <LoadAds Ads={["online2_header"]}/>}
                </AppHeader>
                </div>
                <div>
                    {!IsOnline && <BodyParagraph />}
                    {IsOnline && <OnlineBodyParagraph />}
                </div>
                <div id="Display">
                    <EnableAdUnits Name="page2_display" Network="/21967555500/thinkzone/" Targeting={SessionData.AdUnitTargeting.page2_display}/>
                    <EnableAdUnits Name="online2_display" Network="/21967555500/thinkzone/" Targeting={SessionData.AdUnitTargeting.online2_display}/>
                    <LoadAds Ads={["page2_display"]}/>
                    {IsOnline && <LoadAds Ads={["online2_display"]}/>}
                </div>
                <div>
                    {DisplayFooter && <Footer />}
                    {DisplayOnlineFooter && <OnlineFooter />}
                </div>
          {/* <button onClick={handleClick}>Click me</button>  */}
               
            </div>
            
        </div>
      );
}

export default WatchVideo2;